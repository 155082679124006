<template>
  <div>
    <!-- <p class="text-2xl mb-6">Your Campaign</p> -->
    <page-title :title="$store.state.pageTitle" :breadcrumbs-items="breadcrumbsItems"></page-title>

    <v-tabs background-color="transparent" color="basil" class="mb-4">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab @click="tabValue = 'All'">All</v-tab>
      <v-tab @click="filterCampaign('On Progress')">On Progress</v-tab>
      <v-tab @click="filterCampaign('Completed')">Completed</v-tab>
      <v-spacer></v-spacer>
      
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab icon class="mt-1" color="primary" @click="refresh()" ><v-icon>mdi-sync</v-icon></v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </v-tabs>

    <div v-if="tabValue == 'All'">
      <div class="mx-auto text-center my-12" v-if="showLoadingComponent">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <p>Loading...</p>
      </div>
      <div class="d-flex justify-center my-12 py-12" v-if="!campaigns.length">
        <div>
          <v-img src="@/assets/images/no-data.png" width="120">
          </v-img>
          <span style="display:block; text-align:center;"> Not found! </span>
        </div>
      </div>
      <v-row v-if="!showLoadingComponent">
        <v-col xl="3" lg="3" md="4" sm="6" cols="12" v-for="campaign in campaigns" :key="campaign.no_">
          <CampaignBox :data="campaign"></CampaignBox>
        </v-col>
      </v-row>
    </div>
    <div v-if="tabValue != 'All'">
      <div class="mx-auto text-center my-12" v-if="showLoadingComponent">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <p>Loading...</p>
      </div>
      <div class="d-flex justify-center my-12 py-12" v-if="filteredCampaigns.length == 0">
        <div>
          <v-img src="@/assets/images/no-data.png" width="120">
          </v-img>
          <span style="display:block; text-align:center;"> Not found! </span>
        </div>
      </div>
      <v-row v-if="!showLoadingComponent">
        <v-col xl="3" lg="3" md="4" sm="6" cols="12" v-for="data in filteredCampaigns" :key="data.no_">
          <CampaignBox :data="data"></CampaignBox>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CampaignBox from '@/components/campaign/CampaignBox.vue'

export default {
  components: {
    CampaignBox,
  },
  data: () => ({
    tab: null,
    tabValue: 'All',
    campaigns: [],
    filteredCampaigns: [],
    showLoadingComponent: false,
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/',
      },
      {
        text: 'Campaign',
        disabled: true,
        href: '#',
      },
    ],
  }),
  methods: {
    getData() {
      this.campaigns =[]
      this.showLoadingComponent = true
      this.$axios
        .get('/campaign/data'
          // , { cacheConfig: true, }
        )
        .then(res => {
          if (res) {
            res = res.data
            this.campaigns = res.data

            console.log(this.campaigns);
          }
          this.showLoadingComponent = false

          this.setLocalStorage("campaign_list", this.campaigns, 500);
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$awn.alert('Internal Server Error')
          this.showLoadingComponent = false
        })
    },
    filterCampaign(sts) {
      this.showLoadingComponent = true
      this.tabValue = sts
      this.filteredCampaigns = []
      this.campaigns.forEach(item => {
        if (item.status == this.tabValue) {
          this.filteredCampaigns.push(item)
        }
      })
      this.showLoadingComponent = false
    },
    refresh(){
      this.removeLocalStorage("campaign_list");

      this.getData()
    }
  },
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push('/login')
    }

    this.getData()
  },
  mounted(){
    this.campaigns = this.getLocalStorage("campaign_list");
    document.title = 'Your Campaign'
    this.$store.state.pageTitle = 'Your Campaign'
    this.$store.state.showBackButton = false
  }
}
</script>
