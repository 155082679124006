<template>
  <v-card elevation="6" outlined>
    <v-img
      v-if="data.src !== ''"
      :src="data.src"
      :height="imgHeight"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-img
      v-if="data.src == ''"
      src="@/assets/images/no-image.png"
      :height="imgHeight"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    ></v-img>
    <div style="height: 100px">
      <v-card-title style="font-size: 1rem">{{ ucFirstAll(data.description) }}</v-card-title>
      <v-card-text style="font-size: 0.8rem">{{ data.status }}</v-card-text>
      <!-- <v-card-text class="d-flex align-end mb-6">
              <v-chip :color="chipColor(data.status)" outlined>{{ data.status }}</v-chip>
            </v-card-text> -->
    </div>
    <v-card-actions class="px-0">
      <v-btn icon @click="show = !show" color="mr-2">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text link :to="'/campaign/detail/' + data.no_">View Detail</v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title>Status</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ data.status }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Start Date</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ formatDate(data.starting_date) }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>End Date</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ formatDate(data.ending_date) }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Total Order</v-list-item-title>
            <v-list-item-subtitle class="text-right">Rp. {{ numberFormat(data.total_order, 2) }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Delivered Material</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ numberFormat(data.delivered_material, 2) }}%</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Invoiced</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ numberFormat(data.invoiced, 2) }}%</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Paid</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{ numberFormat(data.invoice_paid, 2) }}%</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'

export default {
  props: {
    data: {},
  },

  data: () => ({
    show: false,
    // icons
    icons: {
      mdiChevronUp,
      mdiChevronDown,
      mdiCartPlus,
      mdiShareVariantOutline,
      mdiLockOpenOutline,
      mdiStarOutline,
      mdiAccountOutline,
      mdiTrendingUp,
      mdiHelpCircleOutline,
    },
  }),

  computed: {
    cardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 440
        case 'sm':
          return 440
        case 'md':
          return 440
        case 'lg':
          return 440
        case 'xl':
          return 440
      }

      return 440
    },
    imgHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 300
        case 'sm':
          return 300
        case 'md':
          return 200
        case 'lg':
          return 200
        case 'xl':
          return 200
      }

      return 200
    },
  },

  methods: {
    chipColor(status) {
      if (status == 'New') {
        return 'primary'
      } else if (status == 'On Progress') {
        return 'orange'
      } else if (status == 'Completed') {
        return 'success'
      } else {
        return 'primary'
      }
    },
  },
}
</script>